import { Link } from "react-router-dom";
import { Carousel, CarouselContent } from "../ui/carousel";
import { motion } from "framer-motion";
import { Badge } from "../ui/badge";
import { Lock } from "lucide-react";

export const CourseModules = (props) => {
  return (
    <motion.div
      initial={{
        scale: 0.5,
        opacity: 0,
      }}
      whileInView={{
        scale: 1,
        opacity: 1,
        transition: {
          type: "spring",
          bounce: 0.2,
          duration: 0.8,
        },
      }}
      className="flex flex-col gap-10 w-full after:bg-black"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <h1 className="text-xl text-[#9D9D9D]">{props.name}</h1>
        <div className="flex justify-start md:justify-end">
          {!props.hasPermission && (
            <Badge color="green" className={"gap-2"}>
              <Lock size={16} />
              Sem acesso a este produto
            </Badge>
          )}
        </div>
      </div>

      <Carousel>
        <CarouselContent>
          {props.modules.map((_, i) => {
            const module = props.modules[i];
            return (
              <Link
                key={i}
                to={`/modulo/${module.id}`}
                className="basis-full md:basis-1/4"
              >
                <motion.div
                  initial={{
                    scale: 0.5,
                    opacity: 0,
                  }}
                  whileInView={{
                    scale: 1,
                    opacity: 1,
                    transition: {
                      type: "spring",
                      bounce: 0.2,
                      duration: 0.8,
                    },
                  }}
                  className="border-[1px] border-zinc-900 basis-1/2 lg:basis-1/4 ml-4 flex flex-col justify-start items-start gap-3"
                  key={i}
                >
                  <img
                    src={
                      process.env.REACT_APP_API_URL + "storage/" + module.image
                    }
                    alt="module"
                    className="h-[200px] w-full object-cover opacity-90 hover:opacity-60 transition-all duration-500"
                  />
                  <div className="p-3 md:p-6 flex flex-col justify-start items-start gap-3">
                    <Badge>{props.name}</Badge>
                    <h1 className="text-md font-regular text-zinc-200">
                      {module.name}
                    </h1>
                    <p className="text-xs text-zinc-300">
                      {module.description}
                    </p>
                  </div>
                </motion.div>
              </Link>
            );
          })}
        </CarouselContent>
      </Carousel>
    </motion.div>
  );
};
