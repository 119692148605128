import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "api",
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      error.config.url !== "/auth/login"
    ) {
      console.log(error);
      // Token expired, remove authorization and redirect
      api.defaults.headers.Authorization = undefined;
      localStorage.removeItem("@App:user");
      localStorage.removeItem("@App:token");

      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default api;
