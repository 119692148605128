import { SlArrowRightCircle } from "react-icons/sl";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Badge } from "./ui/badge";

export const FeaturedCourse = (props) => {
  return (
    <motion.div
      initial={{
        y: 300,
        opacity: 0,
      }}
      whileInView={{
        y: 0,
        opacity: 1,
        transition: {
          type: "spring",
          bounce: 0.2,
          duration: 1.2,
        },
      }}
      className="flex flex-wrap bg-gradient-to-bl from-zinc-800 to-zinc-900 my-12 md:h-[500px]"
    >
      <div
        className="bg-center bg-cover h-[200px] w-[100%] block md:hidden"
        style={{ backgroundImage: `url(${props.image})` }}
      ></div>
      <div className="w-full md:w-[40%] px-5 md:px-20 flex flex-col gap-5 justify-center items-start text-zinc-100 py-5">
        <div className="flex flex-col gap-3 justify-center items-start">
          <p className="text-left text-sm italic font-normal text-zinc-500">
            {" "}
            {props.course}
          </p>
          <h1 className="text-left text-3xl md:text-4xl font-semibold hidden md:block">
            {props.title}
          </h1>
          <p
            className="text-xs md:text-sm text-zinc-300 mt-3
          "
          >
            {props.description}
          </p>
        </div>
        <Link to={`/modulo/${props.id}`}>
          <div className="flex gap-2 items-start">
            <h1 className="text-lg">Assistir módulo completo</h1>
            <SlArrowRightCircle color="white" size={28} />
          </div>
        </Link>
        <Badge>Criado por {props.author}</Badge>
      </div>
      <div
        className="bg-center bg-cover w-[100%] md:w-[60%] rounded-l-xl hidden md:block"
        style={{ backgroundImage: `url(${props.image})` }}
      ></div>
    </motion.div>
  );
};
