import api from "../api";

const BASE_URL = "/auth/me";

export const getMe = async () => {
  const response = await api.get(BASE_URL);
  return response;
};

export const updateMe = async (data) => {
  const response = await api.put(BASE_URL, data);
  return response;
};

export const updateMeProfilePic = async (data) => {
  const response = await api.post(BASE_URL + "/profilepic", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};
