import { useEffect, useState } from "react";
import { Button } from "../ui/button";
import { Check, CheckCheck } from "lucide-react";
import { completeLesson } from "src/services/lessons";

export function WatchedLessonButton(props) {
  const lesson = props.lesson;
  const [watched, setWatched] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const onButtonClick = async () => {
    try {
      if (!watched) {
        setButtonLoading(true);
        await completeLesson(lesson.id);
        setWatched(true);
        setButtonLoading(false);
      }
    } catch (error) {
      setButtonLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    setWatched(lesson.watched);
  }, [lesson]);

  return (
    <Button
      onClick={onButtonClick}
      variant={watched ? "secondary" : "outline"}
      className="flex gap-2"
    >
      {buttonLoading ? (
        <>
          <img
            src={process.env.REACT_APP_PLATFORM_URL + "/anim.gif"}
            alt="loading"
            className="w-20"
          />
        </>
      ) : (
        <>
          {watched ? (
            <>
              <CheckCheck className="w-5" />
              Aula assistida
            </>
          ) : (
            <>
              <Check className="w-5" /> Marcar aula como assistida
            </>
          )}
        </>
      )}
    </Button>
  );
}
