import api from "../api";

const BASE_URL = "/lesson";

export const getLessons = async () => {
  const response = await api.get(BASE_URL);
  return response;
};

export const getLesson = async (id, module = false, notes = false) => {
  const response = await api.get(
    BASE_URL + "/" + id + "?module=" + module + "&notes=" + notes
  );
  return response;
};

export const completeLesson = async (id) => {
  const response = await api.post(BASE_URL + "/completed/", { lesson_id: id });
  return response;
};
