import { Footer } from "src/components/footer";
import Header from "src/components/header/header";
import { Avatar, AvatarFallback, AvatarImage } from "src/components/ui/avatar";
import { Button } from "src/components/ui/button";
import { Card, CardContent } from "src/components/ui/card";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import { User } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { padString } from "src/lib/utils";
import { toast } from "react-toastify";
import { useAuth } from "src/contexts/auth";
import { getMe, updateMe, updateMeProfilePic } from "src/services/me";

export const Profile = () => {
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  const { setAuthUser } = useAuth();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [upload, setUpload] = useState(null);

  const handleSave = async () => {
    if (confirmPassword === newPassword) {
      try {
        await updateMe({
          password: password,
          new_password: newPassword,
          password_confirmation: confirmPassword,
        });
        toast.success("Senha alterada com sucesso");
        navigate("/member");
      } catch (error) {
        toast.error(error.response.data.error);
      }
    } else {
      toast.error("As senhas não coincidem");
    }
  };

  function handleUploadProfilePic() {
    if (upload) {
      const formData = new FormData();
      formData.append("profile_pic", upload);
      updateMeProfilePic(formData)
        .then((response) => {
          toast.success("Foto de perfil atualizada com sucesso");
          setAuthUser(response.data.user);
          setUser(response.data.user);
          setProfilePic(response.data.user.profile_pic);
        })
        .catch((error) => {
          if (error.response.status === 413) {
            toast.error("Foto de perfil muito grande. Max.: 1MB");
            return;
          }
          toast.error("Erro ao atualizar a foto de perfil");
        });
    } else {
      toast.error("Por favor, selecione uma foto para enviar");
    }
  }

  useEffect(() => {
    getMe().then((response) => {
      console.log(response);
      setProfilePic(response.data.profile_pic);
      setUser(response.data);
    });
  }, []);

  return (
    <>
      <Header />
      <div className=" flex items-center justify-center mt-20 px-2">
        <Card className="w-full max-w-lg bg-zinc-900 text-zinc-400 border-zinc-800 rounded-3xl">
          <CardContent className="p-6 space-y-6">
            <div className="flex justify-center">
              <Avatar className="w-24 h-24">
                <AvatarImage
                  alt="Profile picture"
                  src={process.env.REACT_APP_API_URL + "storage/" + profilePic}
                  className="object-cover"
                />
                <AvatarFallback className="bg-zinc-700">
                  <User className="w-12 h-12 text-zinc-500" />
                </AvatarFallback>
              </Avatar>
            </div>

            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="profile-pic" className="text-zinc-500">
                  Foto de Perfil
                </Label>
                <Input
                  id="profile-pic"
                  type="file"
                  accept="image/*"
                  onChange={(event) => setUpload(event.target.files[0])}
                  className="bg-zinc-800 border-zinc-700 text-zinc-300"
                />
                <Button
                  onClick={handleUploadProfilePic}
                  className="w-full bg-zinc-800 text-zinc-300 hover:bg-zinc-700 mt-2"
                >
                  Atualizar Foto de Perfil
                </Button>
              </div>

              <div className="space-y-2">
                <Label htmlFor="name" className="text-zinc-500">
                  Nome
                </Label>
                <div className="flex items-center">
                  <Input
                    id="name"
                    defaultValue={user.name}
                    className="bg-zinc-800 border-zinc-700 text-zinc-300"
                    disabled
                  />
                  <span className="ml-2 text-sm text-zinc-600">
                    #{padString(user.id ? user.id : 1)}
                  </span>
                </div>
              </div>

              <div className="space-y-2">
                <Label htmlFor="email" className="text-zinc-500">
                  E-mail
                </Label>
                <div className="flex gap-2">
                  <Input
                    id="email"
                    type="email"
                    defaultValue={user.email}
                    disabled
                    className="bg-zinc-800 border-zinc-700 text-zinc-300 flex-grow"
                  />
                </div>
              </div>

              <div className="space-y-2">
                <Label htmlFor="current-password" className="text-zinc-500">
                  Senha atual
                </Label>
                <Input
                  id="current-password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="bg-zinc-800 border-zinc-700 text-zinc-300"
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="new-password" className="text-zinc-500">
                  Nova senha
                </Label>
                <Input
                  id="new-password"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="bg-zinc-800 border-zinc-700 text-zinc-300"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="new-password" className="text-zinc-500">
                  Confirmação de senha
                </Label>
                <Input
                  id="confirm-password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="bg-zinc-800 border-zinc-700 text-zinc-300"
                />
              </div>

              <Button
                onClick={handleSave}
                className="w-full bg-zinc-800 text-zinc-300 hover:bg-zinc-700 mt-4"
              >
                Salvar alterações
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
      <Footer />
    </>
  );
};
