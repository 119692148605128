import { toast } from "react-toastify";
import { LoadingContext } from "./loading";
import api from "src/services/api";
import { auth } from "src/services/auth";

const { createContext, useState, useContext, useMemo } = require("react");

const AuthContext = createContext();

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [, setIsLoading] = useContext(LoadingContext);

  useMemo(() => {
    const storagedUser = localStorage.getItem("@App:user");
    const storagedToken = localStorage.getItem("@App:token");

    if (storagedToken && storagedUser) {
      setUser(JSON.parse(storagedUser));
      api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
    }
  }, []);

  function logout() {
    setUser(null);
    localStorage.removeItem("@App:user");
    localStorage.removeItem("@App:token");
    api.defaults.headers.Authorization = undefined;
  }

  function setAuthUser(user) {
    setUser(user);
    localStorage.setItem("@App:user", JSON.stringify(user));
  }

  async function login(email, password, navigate) {
    try {
      const response = await auth(email, password);

      setUser(response.data.user);

      api.defaults.headers.Authorization = `Bearer ${response.data.access_token}`;

      localStorage.setItem("@App:user", JSON.stringify(response.data.user));
      localStorage.setItem("@App:token", response.data.access_token);

      toast.info("Login efetuado com sucesso!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "dark",
        draggable: true,
        progress: undefined,
      });

      setIsLoading(false);
      setTimeout(() => {
        navigate("/member");
      }, 500);

      return response;
    } catch (error) {
      setIsLoading(false);
      toast.error("E-mail ou senha incorretos", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "dark",
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <AuthContext.Provider
      value={{ signed: Boolean(user), user, login, logout, setAuthUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
