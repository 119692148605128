import Header from "src/components/header/header";
import { Footer } from "../../components/footer";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "src/components/ui/breadcrumb";
import { Badge } from "src/components/ui/badge";
import { Switch } from "src/components/ui/switch";
import { LoadingContext } from "src/contexts/loading";
import { getModule } from "src/services/module";

const ModuleBreadcumb = (props) => {
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink href="/member">WinnersHub</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink href="#">{props.course}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>{props.module}</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export const Module = () => {
  const [module, setModule] = useState({});
  const [watched, setWatched] = useState(false);
  const [lessons, setLessons] = useState([]);
  const moduleId = useParams().id;

  const navigate = useNavigate();

  const [, setIsLoading] = useContext(LoadingContext);

  const getModuleRequest = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getModule(moduleId);
      setModule(response.data);
      setLessons(response.data.lessons);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast("Você não tem permissão para acessar este módulo", {
        type: "error",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "dark",
        draggable: true,
        progress: undefined,
      });
      navigate("/member");
    }
  }, []);

  useEffect(() => {
    getModuleRequest();
  }, [getModuleRequest]);

  return (
    <>
      <Header />
      <div className="container py-10 text-white min-h-[60vh]">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div>
            <ModuleBreadcumb
              course={module.course?.name}
              module={module.name}
            />
            <h1 className="text-4xl font-regular mt-5">{module.name}</h1>
          </div>
          <div className="flex items-center justify-start md:justify-end gap-2 mt-5">
            <Switch
              className="block md:hidden"
              checked={watched}
              onCheckedChange={setWatched}
            />
            <h1 className="text-sm text-zinc-300  font-medium">
              Mostrar apenas não assistidas
            </h1>
            <Switch
              className="hidden md:block"
              checked={watched}
              onCheckedChange={setWatched}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-10">
          {lessons.map((lesson) => {
            if (!watched || (watched && !lesson.watched)) {
              return (
                <Link key={lesson.id} to={`/aula/${lesson.id}`}>
                  <motion.div
                    initial={{
                      scale: 0.5,
                      opacity: 0,
                    }}
                    whileInView={{
                      scale: 1,
                      opacity: 1,
                      transition: {
                        type: "spring",
                        bounce: 0.2,
                        duration: 0.8,
                      },
                    }}
                    className="border-[1px] border-zinc-900"
                    key={lesson.id}
                  >
                    <img
                      src={
                        process.env.REACT_APP_API_URL +
                        "storage/" +
                        lesson.image
                      }
                      alt="module"
                      className="h-[150px] w-full object-cover opacity-90 hover:opacity-60 transition-all duration-500"
                    />
                    <div className="p-3 md:p-6 flex flex-col justify-start items-start gap-3">
                      <Badge variant={lesson.watched ? "secondary" : "default"}>
                        {lesson.watched ? "Assistida" : "Não assistida"}
                      </Badge>
                      <h1 className="text-md font-regular text-zinc-200">
                        {lesson.name}
                      </h1>
                      <p className="text-sm text-zinc-400">
                        {lesson.description}
                      </p>
                    </div>
                  </motion.div>
                </Link>
              );
            }
          })}
        </div>
      </div>
      <Footer />
    </>
  );
};
