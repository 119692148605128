import { createContext } from "react";
import "./App.css";
import AppRouter from "./router";

import { AuthProvider } from "./contexts/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingProvider } from "./contexts/loading";

function App() {
  return (
    <>
      <LoadingProvider>
        <AuthProvider>
          <AppRouter />
        </AuthProvider>
      </LoadingProvider>
      <ToastContainer />
    </>
  );
}

export default App;
