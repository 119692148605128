import api from "../api";

const BASE_URL = "/auth";

export const auth = async (email, password) => {
  const response = await api.post(BASE_URL + "/login", {
    email: email,
    password: password,
  });
  return response;
};

export const authLogout = async () => {
  const response = await api.post(BASE_URL + "/logout");
  return response;
};
