import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";
import AuthContext from "src/contexts/auth";

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const { signed } = useContext(AuthContext);
  return (
    <div className="h-screen w-full flex flex-col justify-center items-center">
      <h1 className="text-6xl font-bold text-zinc-100">404</h1>
      <h2 className="text-2xl font-medium text-zinc-500">
        Página não encontrada
      </h2>
      <Button
        className="mt-10"
        onClick={() => navigate(signed ? "/member" : "/")}
      >
        Volte para a plataforma
      </Button>
    </div>
  );
};
