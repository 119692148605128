import api from "../api";

const BASE_URL = "/module";

export const getModules = async () => {
  const response = await api.get(BASE_URL);
  return response;
};

export const getModule = async (id) => {
  const response = await api.get(BASE_URL + "/" + id);
  return response;
};
