import { createContext, useState } from "react";

export const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [gif, setGif] = useState(
    process.env.REACT_APP_PLATFORM_URL + "/anim.gif"
  );
  const [isLoading, setLoading] = useState(false);

  const setIsLoading = async (bool) => {
    await resetGif();
    setTimeout(async () => {
      setLoading(bool);
    }, 0);
  };

  const resetGif = async () => {
    await setGif("");
    setTimeout(async () => {
      await setGif(process.env.REACT_APP_PLATFORM_URL + "/anim.gif?sl=1");
    }, 100);
  };

  return (
    <LoadingContext.Provider value={[isLoading, setIsLoading]}>
      {isLoading && (
        <div className="flex justify-center items-center w-full h-full bg-black/80 fixed top-0 left-0 z-10">
          <img src={gif} alt="loading" className="w-full md:w-[70%]" />
        </div>
      )}
      {children}
    </LoadingContext.Provider>
  );
};
