import Header from "src/components/header/header";
import { Footer } from "../../components/footer";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "src/components/ui/breadcrumb";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { Send } from "lucide-react";
import { Textarea } from "src/components/ui/textarea";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import { format } from "date-fns";
import { CopyNotes } from "src/components/lesson/copy-notes";
import { LoadingContext } from "src/contexts/loading";
import { storeNote } from "src/services/notes";
import { getLesson } from "src/services/lessons";
import { WatchedLessonButton } from "src/components/lesson/watched-lesson-button";

const ModuleBreadcumb = (props) => {
  const navigate = useNavigate();

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink href="/member">WinnersHub</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink
            href="#"
            onClick={() => navigate("/modulo/" + props.courseId)}
          >
            {props.course}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>{props.lesson}</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export const Lesson = () => {
  const [lessons, setLessons] = useState([]);
  const [lesson, setLesson] = useState({});
  const [notes, setNotes] = useState([]);
  const [noteText, setNoteText] = useState("");
  const lessonId = useParams().id;

  const [, setIsLoading] = useContext(LoadingContext);

  const navigate = useNavigate();

  const addNote = async () => {
    try {
      const response = await storeNote(noteText, lessonId);
      setNotes(response.data.notes);
      setNoteText("");
      toast("Anotação salva com sucesso!", {
        type: "success",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "dark",
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      toast("Erro ao salvar anotação.", {
        type: "error",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "dark",
        draggable: true,
        progress: undefined,
      });
    }
  };

  const getLessonRequest = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getLesson(lessonId, true, true);
      setLesson(response.data.lesson);
      setNotes(response.data.notes);
      setLessons(response.data.moduleLessons);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast("Você não tem permissão para acessar essa aula.", {
        type: "error",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "dark",
        draggable: true,
        progress: undefined,
      });
      navigate("/member");
    }
  }, []);

  useEffect(() => {
    getLessonRequest();
  }, [getLessonRequest]);

  return (
    <>
      <Header />
      <div className="container py-10 text-white">
        <ModuleBreadcumb
          course={lesson.module?.name}
          courseId={lesson.module?.id}
          lesson={lesson.name}
        />
        <div className="flex flex-col md:flex-row pt-4 pb-10 w-full justify-between items-start md:items-center gap-2">
          <h1 className="text-2xl font-regular">{lesson.name}</h1>
          <WatchedLessonButton lesson={lesson} />
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "0px",
            paddingBottom: "56.25%",
          }}
        >
          {lesson.video ? (
            <iframe
              title="video"
              allow="fullscreen"
              allowFullScreen
              height="100%"
              src={"https://streamable.com/e/" + lesson.video}
              width="100%"
              style={{
                border: "none",
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0",
                left: "0",
              }}
            />
          ) : (
            <div className="flex flex-col justify-center items-center aspect-video bg-black/20 rounded-2xl border-[1px] border-zinc-900">
              <img
                src={process.env.REACT_APP_PLATFORM_URL + "/anim.gif"}
                alt="loading"
                className="w-32"
              />
              <p className="text-white text-xl">Carregando vídeo</p>
            </div>
          )}
        </div>
        <div className="my-10">
          <div className="flex space-x-2">
            <Textarea
              placeholder="Criar uma anotação..."
              className="flex-grow"
              value={noteText}
              onChange={(e) => setNoteText(e.target.value)}
            />
            <Button onClick={addNote} size="icon">
              <Send className="h-4 w-4" />
            </Button>
          </div>
          <div className="flex flex-col gap-5 mt-10">
            <div className="flex justify-between items-start">
              <h1 className="text-xl font-regular">Suas anotações</h1>
              <CopyNotes notes={notes} />
            </div>
            {notes.map((note) => (
              <Card key={note.id} className="">
                <CardHeader className="p-4">
                  <CardTitle className="text-sm font-medium">
                    {format(note.created_at, "dd/MM/yyyy HH:mm:ss")}
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-4 pt-0">
                  <p className="text-sm">{note.text}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
        <div className="gap-5">
          <h1 className="text-xl font-regular mt-2 mb-10">
            Mais aulas do módulo <b>{lesson?.module?.name}</b>
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
            {lessons.map((lesson) => {
              return (
                <Link key={lesson.id} to={`/aula/${lesson.id}`}>
                  <motion.div
                    initial={{
                      scale: 0.5,
                      opacity: 0,
                    }}
                    whileInView={{
                      scale: 1,
                      opacity: 1,
                      transition: {
                        type: "spring",
                        bounce: 0.2,
                        duration: 0.8,
                      },
                    }}
                    className="border-[1px] border-zinc-900"
                    key={lesson.id}
                  >
                    <img
                      src={
                        process.env.REACT_APP_API_URL +
                        "storage/" +
                        lesson.image
                      }
                      alt="module"
                      className="h-[150px] w-full object-cover opacity-90 hover:opacity-60 transition-all duration-500"
                    />
                    <div className="p-3 md:p-6 flex flex-col justify-start items-start gap-3">
                      <Badge variant={lesson.watched ? "secondary" : "default"}>
                        {lesson.watched ? "Assistida" : "Não assistida"}
                      </Badge>
                      <h1 className="text-md font-regular text-zinc-200">
                        {lesson.name}
                      </h1>
                      <p className="text-sm text-zinc-400">
                        {lesson.description}
                      </p>
                    </div>
                  </motion.div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
