import { Check, Copy } from "lucide-react";
import { useState } from "react";
import { Button } from "../ui/button";
import { format } from "date-fns";
import { toast } from "react-toastify";

export const CopyNotes = ({ notes }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    // Format notes with timestamps and blank lines between them
    const formattedNotes = notes
      .map(
        (note) =>
          `${format(note.created_at, "dd/MM/yyyy HH:mm:ss")}\n${note.text}`
      )
      .join("\n\n");

    navigator.clipboard.writeText(formattedNotes).then(() => {
      setCopied(true);
      toast("Notas copiadas com sucesso!", {
        type: "success",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "dark",
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <Button
      onClick={copyToClipboard}
      variant="outline"
      size="sm"
      className="gap-2 w-[200px]"
    >
      {copied ? (
        <>
          <Check className="h-4 w-4" />
          Copiado!
        </>
      ) : (
        <>
          <Copy className="h-4 w-4" />
          Copiar todas as notas
        </>
      )}
    </Button>
  );
};
