import api from "../api";

const BASE_URL = "/notes";

export const getNotes = async () => {
  const response = await api.get(BASE_URL);
  return response;
};

export const storeNote = async (text, lessonId) => {
  const response = await api.post(BASE_URL, {
    text: text,
    lesson_id: lessonId,
  });
  return response;
};
